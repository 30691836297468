import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  showBackdrop = false;
  enableUiRebranding = environment.enableUiRebranding === 'true';
  fullImageUrl = '';
  galleryImageUrl = 'https://storage.googleapis.com/g5-dexcom-prod-us-5-developer-portal-assets/images/gallery/';
  galleryData = [
    {
      name: '1bios',
      displayName: '1bios',
      url: 'https://1bios.co/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/1bios-health/id918178962?mt=8',
        google: 'https://play.google.com/store/apps/details?id=co.bios.app&amp;hl=en',
      },
      icon: this.galleryImageUrl+'screenshots/icon_spaced2.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/1bios-screenshot-1_dexcom.png',
        this.galleryImageUrl+'screenshots/1bios-screenshot-2_dexcom.png',
        this.galleryImageUrl+'screenshots/1bios-screenshot-3_dexcom.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/1bios-screenshot-1_dexcom.png',
        this.galleryImageUrl+'fullimages/1bios-screenshot-2_dexcom.png',
        this.galleryImageUrl+'fullimages/1bios-screenshot-3_dexcom.png',
      ],
      desc: '1bios health management software supports payers and providers in the shift towards value-based care. We lower healthcare costs with tools that make it more efficient to support the better health of large populations—including people with or at risk of developing diabetes. How? By making it easy for health care organizations to monitor, analyze and stay connected to their members, patients and communities 365 days/year. And, by making it simple, relevant, and rewarding for you to participate.\n\n',
    },
    {
      name: 'achievement',
      displayName: 'Achievement',
      url: 'https://www.myachievement.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/achievemint/id793039965',
        google: 'https://play.google.com/store/apps/details?id=com.achievemint.android',
      },
      icon: this.galleryImageUrl+'screenshots/120x1203x.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/android_1.png',
        this.galleryImageUrl+'screenshots/android_3.png',
        this.galleryImageUrl+'screenshots/android_5.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/android_1.png',
        this.galleryImageUrl+'fullimages/android_3.png',
        this.galleryImageUrl+'fullimages/android_5.png',
      ],
      desc: 'Achievement allows individuals to earn rewards for health actions, receive personalized offers about health programs, and participate in research.\n\n',
    },
    {
      name: 'amc_health',
      displayName: 'AMC Health',
      url: 'https://www.amchealth.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/mycare-virtual-by-amc-health/id1492994842',
        google: 'https://play.google.com/store/apps/details?id=com.amchealth.byod&hl=en_US',
      },
      icon: this.galleryImageUrl+'screenshots/amc_health_logo.svg',
      screenShot: [
        this.galleryImageUrl+'screenshots/amchealth_1.jpg',
        this.galleryImageUrl+'screenshots/amchealth_2.jpg'
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/amchealth_1.jpg',
        this.galleryImageUrl+'fullimages/amchealth_2.jpg',
      ],
      desc: 'AMC Health provides an end-to-end virtual care solution for high-quality, low-cost clinical interventions, improving healthcare access.\n\n',
    },
    {
      name: 'blue_star',
      displayName: 'BlueStar',
      url: 'http://www.bluestardiabetes.com/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/bluestar-diabetes/id700329056?mt=8',
        google:
          'https://play.google.com/store/apps/details?id=com.welldoc.platform.android&hl=en_US',
      },
      icon: this.galleryImageUrl+'screenshots/bluestar_logo_120px.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/logbook_w_entries_cgm_604w.png',
        this.galleryImageUrl+'screenshots/apps_devices_disconnect_604w.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/logbook_w_entries_cgm_604w.png',
        this.galleryImageUrl+'fullimages/apps_devices_disconnect_604w.png',
      ],
      desc: 'Welldoc’s marquee product, BlueStar® is an FDA-cleared, award-winning, digital therapeutic for individuals with diabetes. BlueStar serves as an in-app digital assistant to help people better self-manage the complexities of their diabetes. As a web and mobile app, BlueStar delivers individualized, real-time feedback and longitudinal insights that are actionable and highly-tailored to the individual user. BlueStar connects and shares your progress directly with your care team.\n\n',
    },
    {
      name: 'bioconcious',
      displayName: 'Bio Concious',
      url: 'https://www.bioconscious.tech/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/endobits-companion/id1505619473',
      },
      icon: this.galleryImageUrl+'screenshots/bioconcious_logo.svg',
      screenShot: [
        this.galleryImageUrl+'screenshots/bioconcious_1.png',
        this.galleryImageUrl+'screenshots/bioconcious_2.png',
        this.galleryImageUrl+'screenshots/bioconcious_3.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/bioconcious_1.png',
        this.galleryImageUrl+'fullimages/bioconcious_2.png',
        this.galleryImageUrl+'fullimages/bioconcious_3.png',
      ],
      desc: 'Discover Endobits by Bio-Conscious: the ultimate tool for diabetes care. Our dashboard empowers clinics with AI-driven triage and patient insights, while our companion app equips patients with personalized alerts and foresight.\n\n',
    },
    {
      name: 'care_connection',
      displayName: 'Care Connection',
      url: ' https://www.careconnectionhealth.com/',
      appStoreLink: {
        apple:
          '',
        google:
          '',
      },
      icon: this.galleryImageUrl+'screenshots/care_connection-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/care_connection-screenshot-01.png',
        this.galleryImageUrl+'screenshots/care_connection-screenshot-02.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/care_connection-full-01.png',
        this.galleryImageUrl+'fullimages/care_connection-full-02.png',
      ],
      desc: 'Care Connection\'s Remote Patient Monitoring platform automatically syncs Dexcom data with our dashboards. This data is made available for a patient\'s providers as well as their teams, enabling them to better manage patients’ chronic conditions. With Care Connection you save valuable staff time, stay on top of resident health, maintain CPT code compliance and improve relationships with everyone in the care continuum of a patient.\n\n',
    },
    {
      name: 'care_simple',
      displayName: 'CareSimple',
      url: 'https://caresimple.com/',
      appStoreLink: {
        apple:
          'https://apps.apple.com/us/app/caresimple/id1460047084',
        google:
          'https://play.google.com/store/apps/details?id=com.tactiohealth.rpm2000.tactiopatient',
      },
      icon: this.galleryImageUrl+'screenshots/care_simple-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/care_simple-screenshot-01.png',
        this.galleryImageUrl+'screenshots/care_simple-screenshot-02.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/care_simple-full-01.png',
        this.galleryImageUrl+'fullimages/care_simple-full-02.png',
      ],
      desc: 'CareSimple is a solutions provider for Hospital-at-Home, Remote Patient Monitoring, Chronic Care Management and Remote Therapeutic Monitoring. The CareSimple platform offers a single end-to-end solution that integrates into electronic health records, including Epic, providing payers, health systems and physician groups with software, medical devices, connectivity, logistics, and professional services required to run different virtual care programs leveraging remote monitoring and coaching.\n\n',
    },
    {
      name: 'carium',
      displayName: 'Carium',
      url: 'https://www.go.carium.com/ ',
      appStoreLink: {
        apple:
          'https://apps.apple.com/us/app/carium/id1394730906',
        google:
          'https://play.google.com/store/apps/details?id=com.carium.cariumAndroid&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/carium-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/carium-screenshot-01.png',
        this.galleryImageUrl+'screenshots/carium-screenshot-02.png',
        this.galleryImageUrl+'screenshots/carium-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/carium-full-01.png',
        this.galleryImageUrl+'fullimages/carium-full-02.png',
        this.galleryImageUrl+'fullimages/carium-full-03.png',
      ],
      desc: 'Take an active role in your health and wellness journey in partnership with your healthcare or wellness team. Carium is a free app that provides easy-to-use tools to support your health and wellness goals, and virtually connects you to your trusted care team or wellness coach. Through Carium’s secure technology, along with data from Apple HealthKit and other sources, you and your care team have a single source to support your progress and communicate easily — in real time, from within real life.\n\n',
    },
    {
      name: 'center_health',
      displayName: 'Center Health',
      url: 'https://www.center.health/',
      appStoreLink: {
        apple:
          'https://itunes.apple.com/app/center-the-diabetes-app/id1231963619?utm_source=dexcom&utm_medium=gallery',
        google:
          'https://play.google.com/store/apps/details?id=com.distillr.app&utm_source=dexcom&utm_medium=gallery',
      },
      icon: this.galleryImageUrl+'screenshots/center_health-02.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/1.png',
        this.galleryImageUrl+'screenshots/2.png',
        this.galleryImageUrl+'screenshots/3.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/1.png',
        this.galleryImageUrl+'fullimages/2.png',
        this.galleryImageUrl+'fullimages/3.png',
      ],
      desc: 'Center Health is turning any phone into an AI-based monitoring solution for the 1 in 15 humans who suffer from diabetes. Through the combination of simple logging, collaboration, and the world’s first virtual assistant for people with diabetes, Center empowers patients to see what lifestyle elements are affecting their blood sugar and prompts behavior change to better manage the condition.\n\n',
    },
    {
      name: 'ceras_health',
      displayName: 'Ceras Health',
      url: 'https://cerashealth.com/home',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/ceras-empower/id1590781672',
        google: 'https://play.google.com/store/apps/details?id=com.cerashealth.imhomev2',
      },
      icon: this.galleryImageUrl+'screenshots/ceras_logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/ceras-01.png',
        this.galleryImageUrl+'screenshots/ceras-02.png',
        this.galleryImageUrl+'screenshots/ceras-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/ceras-01.png',
        this.galleryImageUrl+'fullimages/ceras-02.png',
        this.galleryImageUrl+'fullimages/ceras-03.png',
      ],
      desc: 'Ceras Health provides monitoring and care coordination services for providers to manage their patients with diabetes and other conditions.\n\n',
    },
    {
      name: 'codex',
      displayName: 'Codex Health',
      url: 'https://www.codex.health/ ',
      appStoreLink: {
        apple:
          'https://apps.apple.com/us/app/allie-your-wellness-app/id1553634187',
        google:
          '',
      },
      icon: this.galleryImageUrl+'screenshots/codex-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/codex-screenshot-01.png',
        this.galleryImageUrl+'screenshots/codex-screenshot-02.png',
        this.galleryImageUrl+'screenshots/codex-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/codex-full-01.png',
        this.galleryImageUrl+'fullimages/codex-full-02.png',
        this.galleryImageUrl+'fullimages/codex-full-03.png',
      ],
      desc: 'Codex Health has developed provider and patient facing solutions which deliver superior clinical and financial outcomes by identifying at-risk populations through stratification analytics, maximizing patient engagement, enabling robust clinical monitoring capabilities and uncovering potential gaps in therapy. Our products synthesize multiple information sources, and use powerful algorithms and AI to provide analytics and insights that apply from the population health level down to the individual patient.\n\n',
    },
    {
      name: 'cori',
      displayName: 'Cori App',
      url: 'https://cori.care/',
      appStoreLink: {
        apple:
          'https://apps.apple.com/app/apple-store/id1527053345?pt=121500132&ct=DexcomGallery&mt=8',
        google:
          '',
      },
      icon: this.galleryImageUrl+'screenshots/cori-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/cori-screenshot-01.png',
        this.galleryImageUrl+'screenshots/cori-screenshot-02.png',
        this.galleryImageUrl+'screenshots/cori-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/cori-full-01.png',
        this.galleryImageUrl+'fullimages/cori-full-02.png',
        this.galleryImageUrl+'fullimages/cori-full-03.png',
      ],
      desc: 'Cori is the Artificial Intelligence driven Health Coach for diabetes that helps you make the best choices for a healthier lifestyle. Its AI-powered software harnesses all the data from CGMs and sensors in your wristband to guide you. So you won\'t be overwhelmed by the hundreds of small life-changing decisions you have to make in your daily life.\n\n',
    },
    {
      name: 'cronometer',
      displayName: 'Cronometer',
      url: 'https://cronometer.com/',
      appStoreLink: {
        apple:
          'https://apps.apple.com/us/app/cronometer-macro-tracker/id1145935738',
        google:
          'https://play.google.com/store/apps/details?id=com.cronometer.android.gold&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/cronometer-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/cronometer-screenshot-01.png',
        this.galleryImageUrl+'screenshots/cronometer-screenshot-02.png',
        this.galleryImageUrl+'screenshots/cronometer-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/cronometer-full-01.png',
        this.galleryImageUrl+'fullimages/cronometer-full-02.png',
        this.galleryImageUrl+'fullimages/cronometer-full-03.png',
      ],
      desc: 'Cronometer\'s nutrition & calorie tracker is here to support you in reaching your health or weight loss goals. Cronometer helps you develop healthy habits by encouraging you to not just focus on counting calories but on your nutrition as a whole. Cronometer is different from other diet apps and food trackers in that all food comes from lab-analyzed data sources and user submissions are checked for accuracy by our staff. When you log your meals, you can track all your macronutrients, as well as up to 82 micronutrients!\n\n',
    },
    {
      name: 'current_health',
      displayName: 'Current Health',
      url: 'https://www.currenthealth.com/ ',
      appStoreLink: {
        apple: 'https://itunes.apple.com/app/center-the-diabetes-app/id1231963619?utm_source=dexcom&utm_medium=gallery',
        google: 'https://play.google.com/store/apps/details?id=com.distillr.app&utm_source=dexcom&utm_medium=gallery',
      },
      icon: this.galleryImageUrl+'screenshots/current_health_logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/logbook_w_entries_cgm_604w.png',//?
        this.galleryImageUrl+'screenshots/apps_devices_disconnect_604w.png',//?
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/logbook_w_entries_cgm_604w.png',//?
        this.galleryImageUrl+'fullimages/apps_devices_disconnect_604w.png',//?
      ],
      desc: 'Current Health, from Best Buy Health, provides an enterprise care-at-home platform that combines remote patient monitoring, personalized patient engagement, integrated care management tools, and expert support services that enable healthcare organizations to scale healthcare at home with a single, flexible solution.\n\n',
    },
    {
      name: 'diabetic_u',
      displayName: 'Diabetic U',
      url: 'https://diabeticu.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/diabeticu/id6463710372',
        google: 'https://play.google.com/store/apps/details?id=com.diabeticu',
      },
      icon: this.galleryImageUrl+'screenshots/diabeticu_logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/diabeticu_1.png',
        this.galleryImageUrl+'screenshots/diabeticu_2.png',
        this.galleryImageUrl+'screenshots/diabeticu_3.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/diabeticu_1.png',
        this.galleryImageUrl+'fullimages/diabeticu_2.png',
        this.galleryImageUrl+'fullimages/diabeticu_3.png',
      ],
      desc: 'The DiabeticU App: Revolutionize your diabetes management with personalized meal plans, exercise tracking, and insightful glucose analytics.\n\n',
    },
    {
      name: 'diabeto_log',
      displayName: 'Diabeto Log',
      url: 'https://www.cubesoft.fr/diabetolog',
      appStoreLink: {
        apple:
          'https://apps.apple.com/app/apple-store/id1328918499',
        google:
          '',
      },
      icon: this.galleryImageUrl+'screenshots/diabeto_log-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/diabeto_log-screenshot-01.png',
        this.galleryImageUrl+'screenshots/diabeto_log-screenshot-02.png',
        this.galleryImageUrl+'screenshots/diabeto_log-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/diabeto_log-full-01.png',
        this.galleryImageUrl+'fullimages/diabeto_log-full-02.png',
        this.galleryImageUrl+'fullimages/diabeto_log-full-03.png',
      ],
      desc: 'Diabeto log is a mobile log book for storing and analyzing all your diabetes related data. Record effortlessly your day-to-day data and get advanced, easy to understand insights into your diabetes.\n\nEasily search your history to understand how to react to any given situation.Diabeto Log is deeply integrated with the iOS ecosystem: full integration with the Apple Health App, iPad and Apple Watch versions, iCloud sync and more.\n\n',
    },
    {
      name: 'docs_ink',
      displayName: 'DocsInk',
      url: 'https://docsink.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/docsink-connect/id875289316 ',
        google: 'https://play.google.com/store/apps/details?id=com.healthapp.docsink&hl=en_US',
      },
      icon: this.galleryImageUrl+'screenshots/docsink_logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/docsink_1.png',
        this.galleryImageUrl+'screenshots/docsink_2.png',
        this.galleryImageUrl+'screenshots/docsink_3.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/docsink_1.png',
        this.galleryImageUrl+'fullimages/docsink_2.png',
        this.galleryImageUrl+'fullimages/docsink_3.png',
      ],
      desc: 'DocsInk’s core mission is to build beautiful healthcare software that allows our users to generate maximum revenue and reimbursement from value-based and fee for service programs.\n\n',
    },
    {
      name: 'dreamed',
      displayName: 'DreaMed Advisor Pro',
      url: 'https://dreamed-diabetes.com/',
      appStoreLink: {
        apple: '',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/dreamed_circle.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/2_0.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/2_0.png',
      ],
      desc: 'DreaMed Diabetes is a medical device company providing personalized diabetes treatment optimization solutions. DreaMed Advisor Pro applies event-driven adaptive learning to refine its understanding for each individual. The solution analyzes information from CGM, self-monitoring blood glucose (SMBG) and insulin delivery devices, and sends recommendations to healthcare providers on how to optimize patient insulin pump settings for basal rate, carbohydrate ratio (CR) and correction factor (CF).\n\n',
    },
    {
      name: 'enhance_d',
      displayName: 'Enhance-d',
      url: 'https://www.enhance-d.com/',
      appStoreLink: {
        apple: '',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/enhance_d-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/enhance_d-screenshot-01.png',
        this.galleryImageUrl+'screenshots/enhance_d-screenshot-02.png',
        this.galleryImageUrl+'screenshots/enhance_d-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/enhance_d-full-01.png',
        this.galleryImageUrl+'fullimages/enhance_d-full-02.png',
        this.galleryImageUrl+'fullimages/enhance_d-full-03.png',
      ],
      desc: 'Making day-to-day decisions around diabetes is now easier thanks to enhance-d, the single digital hub for diabetes self-management.\n\nWith enhance-d you can automatically integrate all your data sources (glucose, insulin, activity, nutrition, sleep) into one single platform, which will then analyze it and simplify it to provide you with guidance in your diabetes management. Clinicians also have access to the data to improve remote monitoring and streamline communication and therapy indication\n\n',
    },
    {
      name: 'evidation',
      displayName: 'Evidation',
      url: 'http://my.evidation.com',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/evidation-reward-health/id793039965',
        google: 'https://play.google.com/store/apps/details?id=com.achievemint.android&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/evidation-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/evidation-screenshot-01.png',
        this.galleryImageUrl+'screenshots/evidation-screenshot-02.png',
        this.galleryImageUrl+'screenshots/evidation-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/evidation-full-01.png',
        this.galleryImageUrl+'fullimages/evidation-full-02.png',
        this.galleryImageUrl+'fullimages/evidation-full-03.png',
      ],
      desc: 'Learn more about your health and earn rewards with Evidation. Evidation rewards you for health actions and shares personalized insights about your health. You can also join one of our health programs or participate in cutting-edge health research.\n\n',
    },
    {
      name: 'fitabase',
      displayName: 'Fitabase',
      url: 'http://www.fitabase.com/',
      appStoreLink: {
        apple: '',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/circlelogo_120_120.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/dexcom_resize_1.png',
        this.galleryImageUrl+'screenshots/dexcom_resize_2.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/dexcom_resize_1.png',
        this.galleryImageUrl+'fullimages/dexcom_resize_2.png',
      ],
      desc: 'Fitabase is a data management platform that allows researchers to capture and analyze high-resolution data from wearable devices, including Dexcom’s CGM systems. Data streams directly to Fitabase and can be exported from individual participants or in custom batches for your whole study. Fitabase has been helping researchers access and make sense of data from wearable devices since 2012, and since has supported more than 500 studies.\n\n',
    },
    {
      name: 'garmin',
      displayName: 'Garmin',
      url: 'garmin.com/Dexcom.',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/connect-iq-store/id1317652970',
        google: 'https://play.google.com/store/apps/details?id=com.garmin.connectiq&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/garmin-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/garmin-screenshot-01.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/garmin-full-01.png',
      ],
      desc: 'Now you can view your glucose levels from your Dexcom G6 Continuous Glucose Monitoring (CGM) System on a Garmin smartwatch or cycling computer with Dexcom apps1 available from the Garmin Connect IQ™ Store2. See your data anytime, even during activities, without having to open an app on your smartphone3. Download the Connect IQ store app on your smartphone, then search for Dexcom to download the apps on your compatible Garmin product.\n\n1 The Dexcom apps on Connect IQ are only for secondary display of data from the Dexcom CGM system and are for passive monitoring purposes only. The Dexcom apps on Connect IQ are not intended to replace the Dexcom CGM System, nor replace self-monitoring practices as advised by a physician. All therapeutic decisions, including those regarding calculating insulin or other drug doses, should not be based on data displayed in the Dexcom apps on Connect IQ.\n\n2 Dexcom G6 CGM System required for use\n\n3 Compatible smartphone must have a data connection and be in BLE range of both the Dexcom G6 CGM and Garmin smartwatch or Edge cycling computer',
    },
    {
      name: 'genie_md',
      displayName: 'GenieMD',
      url: 'https://www.geniemd.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/geniemd-remote-care/id6443430749',
        google: 'https://play.google.com/store/apps/details?id=com.geniemd.remoteCare&hl=en_US&gl=US ',
      },
      icon: this.galleryImageUrl+'screenshots/genie_md-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/genie_md-screenshot-01.png',
        this.galleryImageUrl+'screenshots/genie_md-screenshot-02.png',
        this.galleryImageUrl+'screenshots/genie_md-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/genie_md-full-01.png',
        this.galleryImageUrl+'fullimages/genie_md-full-02.png',
        this.galleryImageUrl+'fullimages/genie_md-full-03.png',
      ],
      desc: 'GenieMD is an award-winning, global provider of modular, scalable and customizable virtual care platform, inclusive of: Telehealth, Remote Patient Monitoring (RPM), Remote Examination, Remote Therapeutic Monitoring (RTM), and Chronic Care Management (CCM), coupled with access to national network of medical professionals, in-home lab services/kit delivery and pharmacy logistics. GenieMD maximizes the potential of cloud computing, big data, artificial intelligence, and mobile technologies. GenieMD is democratizing healthcare.   \n\n',
    },
    {
      name: 'glooko',
      displayName: 'Glooko',
      url: 'http://glooko.com/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/glooko-track-diabetes-data/id471942748',
        google: 'https://play.google.com/store/apps/details?id=com.glooko.logbook',
      },
      icon: this.galleryImageUrl+'screenshots/glooko-logo-large.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/ios_home_.png',
        this.galleryImageUrl+'screenshots/ios_history_png.png',
        this.galleryImageUrl+'screenshots/ios_overlay.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/ios_home_.png',
        this.galleryImageUrl+'fullimages/ios_history_png.png',
        this.galleryImageUrl+'fullimages/ios_overlay.png',
      ],
      desc: 'Glooko insights help people with diabetes (PWD) and care teams improve diabetes management. The Glooko Mobile App enables self-management and automatically uploads data from blood glucose meters, insulin pumps, CGMs and fitness and activity trackers—providing timely and verified data and enabling PWDs and care teams to correlate it with diet, exercise, medication and other data. Trusted by the world’s leaders in diabetes care, Glooko is used by 7,000 clinics and 1.5 million PWD globally.\n\n',
    },
    {
      name: 'glucose_buddy',
      displayName: 'Glucose Buddy',
      url: 'https://azumio.com/solutions#diabetes',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/glucose-buddy-diabetes-tracker/id294754639',
        google: 'https://play.google.com/store/apps/details?id=com.skyhealth.glucosebuddyfree&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/glucose_buddy-02.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/dexcom-gb-screenshot1_2.png',
        this.galleryImageUrl+'screenshots/dexcom-gb-screenshot2_1.png',
        this.galleryImageUrl+'screenshots/dexcom-gb-screenshot3_1.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/dexcom-gb-screenshot1_2.png',
        this.galleryImageUrl+'fullimages/dexcom-gb-screenshot2_1.png',
        this.galleryImageUrl+'fullimages/dexcom-gb-screenshot3_1.png',
      ],
      desc: 'For over 14 years, Glucose Buddy has been the go-to solution to help people manage their diabetes. Track glucose, insulin, medication, diet and much more. Meal IQ combines our class leading food recognition system with your glucose measurements to help you understand how the meals you eat impact your blood sugar. With Meal IQ, you will receive food grades after each meal so you can make better dietary choices, as well as detailed reports to help you regulate your glucose and A1C.\n\n',
    },
    {
      name: 'glucose_zone',
      displayName: 'GlucoseZone',
      url: 'https://glucosezone.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/glucosezone/id1140645765',
        google: 'https://play.google.com/store/apps/details?id=com.fitscript.glucosezone',
      },
      icon: this.galleryImageUrl+'screenshots/gzlogo-120px.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/image_from_ios-2.jpg',
        this.galleryImageUrl+'screenshots/iphone_x_copy_23.png',
        this.galleryImageUrl+'screenshots/iphone_x_copy_21.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/image_from_ios-2.png',
        this.galleryImageUrl+'fullimages/iphone_x_copy_23.png',
        this.galleryImageUrl+'fullimages/iphone_x_copy_21.png',
      ],
      desc: 'GlucoseZone is a digital therapeutic that helps you meet the exercise requirement of your diabetes management. GlucoseZone provides you with individualized exercise guidance based on your glucose levels and other health metrics. At GlucoseZone, we understand that exercise is different for people living with diabetes, starting with the need to check glucose—let GlucoseZone help you say yes to exercise.\n\n',
    },
    {
      name: 'glucura',
      displayName: 'Glucura (By Perfood)',
      url: 'https://glucura.de/',
      appStoreLink: {
        apple: 'https://apps.apple.com/at/app/glucura-typ-2-diabetes-coach/id1607065288?l=en-GB',
        google: 'https://play.google.com/store/apps/details?id=de.perfood.diabetes',
      },
      icon: this.galleryImageUrl+'screenshots/glucura_logo.svg',
      screenShot: [
        this.galleryImageUrl+'screenshots/glucura_1.png',
        this.galleryImageUrl+'screenshots/glucura_2.png',
        this.galleryImageUrl+'screenshots/glucura_3.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/glucura_1.png',
        this.galleryImageUrl+'fullimages/glucura_2.png',
        this.galleryImageUrl+'fullimages/glucura_3.png',
      ],
      desc: 'Digital lifestyle therapy for Type 2 diabetes, offering personalized glucose meal feedback, nutritional advice, exercises, education & weekly goals.\n\n',
    },
    {
      name: 'glutality',
      displayName: 'Glutality',
      url: 'https://glutality.com/',
      appStoreLink: {
      },
      icon: this.galleryImageUrl+'screenshots/glutality_logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/glutality_1.jpg',
        this.galleryImageUrl+'screenshots/glutality_2.jpg',
        this.galleryImageUrl+'screenshots/glutality_3.jpg',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/glutality_1.jpg',
        this.galleryImageUrl+'fullimages/glutality_2.jpg',
        this.galleryImageUrl+'fullimages/glutality_3.jpg',
      ],
      desc: 'Glutality, a leader in diabetes management, is committed to enhancing both care outcomes and accessibility. By integrating remote patient monitoring with Dexcom\'s Continuous Glucose Monitoring (CGM) systems, we offer personalized, attentive care for those managing diabetes.\n\n',
    },
    {
      name: 'guava',
      displayName: 'Guava Health',
      url: 'https://guavahealth.com',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/guava-personal-health-tracker/id1622255863',
        google: 'hhttps://play.google.com/store/apps/details?id=com.guavahealth.app',
      },
      icon: this.galleryImageUrl+'screenshots/guava-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/guava-screenshot-01.png',
        this.galleryImageUrl+'screenshots/guava-screenshot-02.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/guava-full-01.png',
        this.galleryImageUrl+'fullimages/guava-full-02.png',
      ],
      desc: 'Guava is your personal health tracker, allowing you to connect all of your patient portals (MyChart etc.), and health devices (Dexcom, Fitbit, etc.) in one place. Guava automatically extracts information like lab results from PDFs and images you upload, and combines all your medical data into simple charts to see your trends over time. Your Guava profile can be easily and securely shared with family or new doctors. Guava shows personalized recommendations from health authorities such as the CDC.\n\n',
    },
    {
      name: 'happy_bob',
      displayName: 'Happy Bob',
      url: 'https://happybob.app/',
      appStoreLink: {
        apple: 'https://apps.apple.com/app/happy-bob/id1444877516',
        google: 'https://play.google.com/store/apps/details?id=com.haraldai.happybob&hl=en',
      },
      icon: this.galleryImageUrl+'screenshots/happy_bob-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/happy_bob-screenshot-01.png',
        this.galleryImageUrl+'screenshots/happy_bob-screenshot-02.png',
        this.galleryImageUrl+'screenshots/happy_bob-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/happy_bob-full-01.png',
        this.galleryImageUrl+'fullimages/happy_bob-full-02.png',
        this.galleryImageUrl+'fullimages/happy_bob-full-03.png',
      ],
      desc: 'Happy Bob is a personal digital health companion aimed at helping you improve your diabetes management. With Bob by your side, you can reduce the stress that comes with diabetes data overload while still achieving better glycemic control*.\n\nAs a secondary CGM display, Happy Bob delivers your blood glucose values alongside messages from one of our many characters. This personalized delivery model allows you to feel more like a person than a patient, as this app is far from the everyday medical device format.\n\n',
    },
    {
      name: 'heads_up',
      displayName: 'Heads Up',
      url: 'https://headsuphealth.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/heads-up-health-analytics/id1399133678',
        google: 'https://play.google.com/store/apps/details?id=com.headsuphealth.app&hl=en_US',
      },
      icon: this.galleryImageUrl+'screenshots/heads_up-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/heads_up-screenshot-01.png',
        this.galleryImageUrl+'screenshots/heads_up-screenshot-02.png',
        this.galleryImageUrl+'screenshots/heads_up-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/heads_up-full-01.png',
        this.galleryImageUrl+'fullimages/heads_up-full-02.png',
        this.galleryImageUrl+'fullimages/heads_up-full-03.png',
      ],
      desc: '"Heads Up is pioneering a data-driven approach to personalized healthcare by unifying clinical, nutritional, and lifestyle data onto a unified dashboard.\n\nIndividuals can create their own personalized dashboards with data from wearables, apps and medical facilities to more effectively self-manage. Practitioners can deploy personalized dashboards to their patients for remote monitoring and lifestyle optimization.\n\nHeads Up health dashboards are used by functional medicine practitioners, telemedicine platforms, health coaches, elite performers, sports teams and biohackers in over 60 countries globally."\n\n',
    },
    {
      name: 'health_arc',
      displayName: 'Health Arc',
      url: 'http://www.healtharc.io/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/healtharc-patient-monitoring/id1510376845',
        google: 'https://play.google.com/store/apps/details?id=com.health.healtharc&pcampaignid=web_share',
      },
      icon: this.galleryImageUrl+'screenshots/healtharc-logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/healtharc_1.png',
        this.galleryImageUrl+'screenshots/healtharc_2.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/healtharc_1.png',
        this.galleryImageUrl+'fullimages/healtharc_2.png',
      ],
      desc: 'HealthArc is a medical software app used for remote patient monitoring and telemedicine.  It’s an invite-only program offered through your health plan or health care provider.\n\n',
    },
    {
      name: 'health_wealth_safe',
      displayName: 'Health Wealth Safe',
      url: 'https://healthwealthsafe.com/',
      appStoreLink: {
        apple:
          'https://apps.apple.com/us/app/health-wealth-safe/id1432361018',
        google:
          'https://play.google.com/store/apps/details?id=com.ephibank&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/health_wealth_safe-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/health_wealth_safe-screenshot-01.png',
        this.galleryImageUrl+'screenshots/health_wealth_safe-screenshot-02.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/health_wealth_safe-full-01.png',
        this.galleryImageUrl+'fullimages/health_wealth_safe-full-02.png',
      ],
      desc: 'The Health Wealth Safe app helps you Track, Monitor, and Manage your health right from your phone. Import secure health data from multiple sources and share it instantly with your Doctor and Family Members. Customize your Care Team and begin making  informed decisions about your health. Engage in your healthcare journey by creating custom goals, answering daily health surveys, and completing health activities. With the Health Wealth Safe app you will unlock the healthier you.\n\n',
    },
    {
      name: 'healthie',
      displayName: 'Healthie',
      url: 'https://www.gethealthie.com/',
      appStoreLink: {
        apple:
          'https://itunes.apple.com/us/app/healthie-for-modern-nutritional-care/id1112029170?mt=8',
        google:
          'https://play.google.com/store/apps/details?id=com.healthie.app.healthie',
      },
      icon: this.galleryImageUrl+'screenshots/healthie-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/healthie-screenshot-01.png',
        this.galleryImageUrl+'screenshots/healthie-screenshot-02.png',
        this.galleryImageUrl+'screenshots/healthie-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/healthie-full-01.png',
        this.galleryImageUrl+'fullimages/healthie-full-02.png',
        this.galleryImageUrl+'fullimages/healthie-full-03.png',
      ],
      desc: 'Healthie offers infrastructure for next-generation digital health organizations that provide virtual-first care. Our API-first and fully brandable suite of solutions (scheduling, EMR, client engagement) enables healthcare builders to launch and scale best-in-class experiences for their members.\n\n',
    },
    {
      name: 'healthy_connect',
      displayName: 'Healthy Connect',
      url: 'https://www.remotecarepartners.com',
      appStoreLink: {
        apple:
          '',
        google:
          '',
      },
      icon: this.galleryImageUrl+'screenshots/healthy_connect-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/healthy_connect-screenshot-01.png',
        this.galleryImageUrl+'screenshots/healthy_connect-screenshot-02.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/healthy_connect-full-01.png',
        this.galleryImageUrl+'fullimages/healthy_connect-full-02.png',
      ],
      desc: 'HealthyConnect is a Virtual Care provider which specializes in helping Patients manage chronic health conditions, including Diabetic Patients using Dexcom CGM. We partner with Healthcare Organizations to provide ongoing care to Patients.   Our nurse coaches monitor longer term trends with each patient, and we provide a video hub, scales, blood pressure devices, and more to support a healthier lifestyle.  We even include trackers like Fitbits for patients and monitor sleep and physical activity.\n\n',
    },
    {
      name: 'heart_cloud',
      displayName: 'HeartCloud',
      url: 'https://heartcloud.io/',
      appStoreLink: {
        apple:
          'https://apps.apple.com/us/app/heartcloud-sync/id1467105319',
        google:
          '',
      },
      icon: this.galleryImageUrl+'screenshots/heart_cloud-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/heart_cloud-screenshot-01.png',
        this.galleryImageUrl+'screenshots/heart_cloud-screenshot-02.png',
        this.galleryImageUrl+'screenshots/heart_cloud-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/heart_cloud-full-01.png',
        this.galleryImageUrl+'fullimages/heart_cloud-full-02.png',
        this.galleryImageUrl+'fullimages/heart_cloud-full-03.png',
      ],
      desc: 'The HeartCloud digital health platform can improve diabetes care by combining blood glucose data with information on sleep, exercise, heart rate, activity and other key health information. That combination gives patients and their healthcare providers line-of-sight into treatment effectiveness and opportunities for additional improvements. Data-driven care decisions may improve diabetes outcomes and reduce the risk of complications.\n\n',
    },
    {
      name: 'hearty',
      displayName: 'Hearty',
      url: 'https://www.joinhearty.com/',
      appStoreLink: {
        apple:
          'https://apps.apple.com/us/app/hearty-live-younger/id6444662944',
        google:
          '',
      },
      icon: this.galleryImageUrl+'screenshots/hearty-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/hearty-screenshot-01.png',
        this.galleryImageUrl+'screenshots/hearty-screenshot-02.png',
        this.galleryImageUrl+'screenshots/hearty-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/hearty-full-01.png',
        this.galleryImageUrl+'fullimages/hearty-full-02.png',
        this.galleryImageUrl+'fullimages/hearty-full-03.png',
      ],
      desc: 'Hearty offers virtual longevity and preventative care by leveraging precision testing, personalized biometrics from wearables to improve health outcomes by empowering patients to fight unhealthy aging.\n\n',
    },
    {
      name: 'inpen',
      displayName: 'InPen',
      url: 'www.myinpen.com',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/inpen-diabetes-management-app/id1236236656',
        google: 'https://play.google.com/store/apps/details?id=com.companionmedical.inpen&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/inpen-02.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/inpen_slide_2.png',
        this.galleryImageUrl+'screenshots/inpen_slide_1.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/inpen_slide_2.png',
        this.galleryImageUrl+'fullimages/inpen_slide_1.png',
      ],
      desc: 'InPen™ smart insulin pen\n\nInPen smart insulin pen, by Medtronic, combines a smart insulin pen with an easy-to-use smartphone app. Using information transmitted from the pen, the InPen App automatically records insulin doses, tracks active insulin, calculates mealtime and correction doses, shares therapy data with your doctor or caregiver, and much more.\n\n',
    },
    {
      name: 'insulclock',
      displayName: 'Insulclock',
      url: 'https://insulclock.com/en/',
      appStoreLink: {
        apple:
          'https://apps.apple.com/us/app/diabetes-control-insulclock/id1365017069',
        google:
          'https://play.google.com/store/apps/details?id=com.insulclock.insulclockAndroid&hl=es_419',
      },
      icon: this.galleryImageUrl+'screenshots/insulclock-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/insulclock-screenshot-01.png',
        this.galleryImageUrl+'screenshots/insulclock-screenshot-02.png',
        this.galleryImageUrl+'screenshots/insulclock-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/insulclock-full-01.png',
        this.galleryImageUrl+'fullimages/insulclock-full-02.png',
        this.galleryImageUrl+'fullimages/insulclock-full-03.png',
      ],
      desc: 'INSULCLOCK 360 is a medical device software developed for featuring manual data entry, storage, display, transfer, and diabetes management taking into account different parameters provided by the patient itself and other devices used by the patient such as glucometers, continuous glucose monitors (CGM), smart watches or activity bracelets, smart insulin pens and smart caps or devices for disposable insulin pens.\n\n',
    },
    {
      name: 'kura_care',
      displayName: 'Kura Care',
      url: 'https://www.kuracare.net/',
      appStoreLink: {
        apple:
          'https://apps.apple.com/us/app/kardiopac/id1540928230',
        google:
          'https://play.google.com/store/apps/details?id=com.d2d.mobile_patient.kuracare&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/kura_care-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/kura_care-screenshot-01.png',
        this.galleryImageUrl+'screenshots/kura_care-screenshot-02.png',
        this.galleryImageUrl+'screenshots/kura_care-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/kura_care-full-01.png',
        this.galleryImageUrl+'fullimages/kura_care-full-02.png',
        this.galleryImageUrl+'fullimages/kura_care-full-03.png',
      ],
      desc: 'Kura Care provides turnkey remote care management solutions to help drive patient’s adherence to their clinical care plans.\n\n',
    },
    {
      name: 'life_omic',
      displayName: 'LifeOmic',
      url: 'https://lifeomic.com/',
      appStoreLink: {
        apple:
          'https://apps.apple.com/us/app/lifeomic/id1533145434',
        google:
          'https://play.google.com/store/apps/details?id=com.lifeomic.liferesearch&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/life_omic-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/life_omic-screenshot-01.png',
        this.galleryImageUrl+'screenshots/life_omic-screenshot-02.png',
        this.galleryImageUrl+'screenshots/life_omic-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/life_omic-full-01.png',
        this.galleryImageUrl+'fullimages/life_omic-full-02.png',
        this.galleryImageUrl+'fullimages/life_omic-full-03.png',
      ],
      desc: 'The LifeOmic App connects patients and study participants with healthcare practitioners, clinicians and researchers. With this interactive mobile app, you can receive care plans, personalized notifications, task checklists, automated AI messaging, educational content, surveys, medical device and wearable integrations (including Dexcom) and behavioral tracking.\nAll data acquired from the app is stored in the LifeOmic platform\n\n',
    },
    {
      name: 'malama',
      displayName: 'Malama',
      url: 'https://heymalama.co/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/malama-gestational-diabetes/id1626320933',
        google: 'https://play.google.com/store/apps/details?id=com.malamareactnative&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/malama_logo.svg',
      screenShot: [
        this.galleryImageUrl+'screenshots/malama_1.png',
        this.galleryImageUrl+'screenshots/malama_2.png',
        this.galleryImageUrl+'screenshots/malama_3.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/malama_1.png',
        this.galleryImageUrl+'fullimages/malama_2.png',
        this.galleryImageUrl+'fullimages/malama_3.png',
      ],
      desc: 'Track glucose and meals with personalized insights for gestational diabetes.\n\n',
    },
    {
      name: 'md_revolution',
      displayName: 'MD Revolution',
      url: 'https://mdrevolution.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/revup-care/id917895189',
        google: 'https://play.google.com/store/apps/details?id=com.revup&hl=en_US',
      },
      icon: this.galleryImageUrl+'screenshots/md_revolution_logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/md_revolution_1.png',
        this.galleryImageUrl+'screenshots/md_revolution_2.png',
        this.galleryImageUrl+'screenshots/md_revolution_3.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/md_revolution_1.png',
        this.galleryImageUrl+'fullimages/md_revolution_2.png',
        this.galleryImageUrl+'fullimages/md_revolution_3.png',
      ],
      desc: 'RevUp by MD Revolution connects patients with clinical care teams utilizing our integrated care management platform, multi-channel patient engagement tools, and EHR integrations to deliver scalable care management programs.\n\n',
    },
    {
      name: 'meteda',
      displayName: 'Meteda',
      url: 'http://www.meteda.com/',
      appStoreLink: {
        apple: '',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/logo-meteda-120x120px.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/meteda_app_gallery_1.png',
        this.galleryImageUrl+'screenshots/meteda_app_gallery_2.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/meteda_app_gallery_1.png',
        this.galleryImageUrl+'fullimages/meteda_app_gallery_2.png',
      ],
      desc: "METEDA's MètaClinic is a professional electronic health record to manage clinical data of patients with chronic conditions like diabetes. The system facilitates storing and processing of clinical and self-monitoring data of patients. Efficiently cataloged and with advanced Clinical Decision Support Tools, data can be quickly consulted both by hospitals and outpatient facilities.\n\n",
    },
    {
      name: 'micare',
      displayName: 'MiCare Health',
      url: 'https://micarepath.com/ ',
      appStoreLink: {
        apple: 'https://play.google.com/store/apps/details?id=com.pulsellc.micarehealth&hl=en_US&gl=US',
        google: 'https://play.google.com/store/apps/details?id=com.pulsellc.micarehealth&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/micare-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/micare-screenshot-01.png',
        this.galleryImageUrl+'screenshots/micare-screenshot-02.png',
        this.galleryImageUrl+'screenshots/micare-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/micare-full-01.png',
        this.galleryImageUrl+'fullimages/micare-full-02.png',
        this.galleryImageUrl+'fullimages/micare-full-03.png',
      ],
      desc: "Building a closer connection with an ever-growing patient population, MiCare Health is a reimbursable integrated virtual care platform delivering improved outcomes with data-driven decisions, personalized patient education and care paths promoting clinical efficiencies for a better patient experience. MiCare Health believes quality healthcare and touch points should not be limited to in-office availability or a patient's zip code.\n\n",
    },
    {
      name: 'mozzaz',
      displayName: 'Mozzaz',
      url: 'https://www.mozzaz.com/',
      appStoreLink: {
        apple: '',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/mozzaz-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/mozzaz-screenshot-01.png',
        this.galleryImageUrl+'screenshots/mozzaz-screenshot-02.png',
        this.galleryImageUrl+'screenshots/mozzaz-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/mozzaz-full-01.png',
        this.galleryImageUrl+'fullimages/mozzaz-full-02.png',
        this.galleryImageUrl+'fullimages/mozzaz-full-03.png',
      ],
      desc: "Mozzaz is a versatile digital health platform that drives actionable insights and personalized care experiences for both patients and providers. Our platform can be tailored to broad population-level engagement models or to individual care plans that are designed for specific cohorts' needs. We provide a connected-care experience between patients and their care teams to drive better clinical outcomes and increase patient engagement with their health.\n\n",
    },
    {
      name: 'my_diabby',
      displayName: 'myDiabby',
      url: 'https://www.mydiabby.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/fr/app/mydiabby/id1250774682',
        google: 'https://play.google.com/store/apps/details?id=fr.be4life.mydiabbydt&hl=fr',
      },
      icon: this.galleryImageUrl+'screenshots/my_diabby-02.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/picture1.png',
        this.galleryImageUrl+'screenshots/picture2.png',
        this.galleryImageUrl+'screenshots/picture3.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/picture1.png',
        this.galleryImageUrl+'fullimages/picture2.png',
        this.galleryImageUrl+'fullimages/picture3.png',
      ],
      desc: 'MyDiabby enhances diabetes healthcare thanks to a patient mobile app to collect everything they need to manage diabetes in one place : log book with connected devices, educational content, messenger + video call with their usual healthcare team. HCPs have their own online app to manage better their patients, save 50% of their time and perform telemedicine.\n\n',
    },
    {
      name: 'one_two_diabetes',
      displayName: 'OneTwo Diabetes',
      url: 'https://onetwo-diabetes.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/se/app/id1630397263',
        google: 'https://play.google.com/store/apps/details?id=com.ddanalytics.onetwo.android',
      },
      icon: this.galleryImageUrl+'screenshots/one_two_diabetes-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/one_two_diabetes-screenshot-01.png',
        this.galleryImageUrl+'screenshots/one_two_diabetes-screenshot-02.png',
        this.galleryImageUrl+'screenshots/one_two_diabetes-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/one_two_diabetes-full-01.png',
        this.galleryImageUrl+'fullimages/one_two_diabetes-full-02.png',
        this.galleryImageUrl+'fullimages/one_two_diabetes-full-03.png',
      ],
      desc: 'OneTwo Diabetes interprets all your glucose data and provides easy to understand reports for type 1 or type 2 diabetes treatment.\n\nAs more people with diabetes start using CGM to collect data we see that many find it hard to interpret their data. We are using machine learning to analyze glucose data to identify meals, root causes for hyper- and hypoglycemia and much more. OneTwo Diabetes is CE marked and is meant for personal use or as a parent of a child with diabetes.\n\n',
    },
    {
      name: 'perry_health',
      displayName: 'Perry Health',
      url: 'https://www.perryhealth.com/ ',
      appStoreLink: {
      },
      icon: this.galleryImageUrl+'screenshots/perry_health_logo.svg',
      screenShot: [
        this.galleryImageUrl+'screenshots/perryhealth_1.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/perryhealth_1.png',
      ],
      desc: 'The Perry Health App is the internal EHR for Perry Health’s care team.\n\n',
    },
    {
      name: 'prevounce',
      displayName: 'Prevounce Health',
      url: 'https://www.prevounce.com/',
      appStoreLink: {
      },
      icon: this.galleryImageUrl+'screenshots/prevounce_logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/prevounce_1.png',
        this.galleryImageUrl+'screenshots/prevounce_2.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/prevounce_1.png',
        this.galleryImageUrl+'fullimages/prevounce_2.png',
      ],
      desc: 'Prevounce is a healthcare software company simplifying the provision of preventive, virtual/remote, and chronic care management services.\n\n',
    },
    {
      name: 'rimidi',
      displayName: 'Rimidi',
      url: 'https://www.rimidi.com/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/diabetes-me/id1072889742',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/screenshot-mobile-my-info.png',
        this.galleryImageUrl+'screenshots/screenshot-mobile-dashboard.png',
        this.galleryImageUrl+'screenshots/screenshot-log.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/screenshot-mobile-my-info.png',
        this.galleryImageUrl+'fullimages/screenshot-mobile-dashboard.png',
        this.galleryImageUrl+'fullimages/screenshot-log.png',
      ],
      desc: "Rimidi's clinical management platform marries patient generated data, including BGM and CGM, with clinical data from the EMR into a streamlined user experience optimized for workflow efficiency. Clinicians monitor key quality measures, triage patient needs, and identify specific gaps in care. Our unique predictive analytics allow clinicians to visualize the anticipated impact of treatment decisions on patient outcomes. Patients get timely feedback and better insight into their care.\n\n",
    },
    {
      name: 'rx_food',
      displayName: 'Rx Food',
      url: 'https://rxfood.co/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/rxfood/id1482235880',
        google: 'https://play.google.com/store/apps/details?id=com.inneranalytics.rxfood&hl=en_US',
      },
      icon: this.galleryImageUrl+'screenshots/rxfood_logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/rxfood_1.png',
        this.galleryImageUrl+'screenshots/rxfood_2.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/rxfood_1.png',
        this.galleryImageUrl+'fullimages/rxfood_2.png',
      ],
      desc: 'Clinically tested, AI-driven personalized nutrition for better health outcomes and patient care.\n\n',
    },
    {
      name: 'seqster',
      displayName: 'Seqster',
      url: 'https://seqster.com/',
      appStoreLink: {
        apple: '',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/seqster_120x120.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/seqster_image1_604px.png',
        this.galleryImageUrl+'screenshots/seqster_image2_604px.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/seqster_image1_604px.png',
        this.galleryImageUrl+'fullimages/seqster_image2_604px.png',
      ],
      desc: 'Seqster is a data-driven healthcare SaaS platform that empowers your members to collect, own, and share their data. By gathering all health data in one place, Seqster allows their users to visualize their health in a meaningful way. Seqster can connect over 9,300 healthcare providers, dozens of major health/fitness devices, and the top genetic consumer labs around.\n\n',
    },
    {
      name: 'snaq',
      displayName: 'SNAQ',
      url: 'https://www.snaq.io',
      appStoreLink: {
        apple: 'https://snaq.onelink.me/vRM0/9b8e3c21',
        google: 'https://snaq.onelink.me/vRM0/9b8e3c21',
      },
      icon: this.galleryImageUrl+'screenshots/snaq-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/snaq-screenshot-01.png',
        this.galleryImageUrl+'screenshots/snaq-screenshot-02.png',
        this.galleryImageUrl+'screenshots/snaq-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/snaq-full-01.png',
        this.galleryImageUrl+'fullimages/snaq-full-02.png',
        this.galleryImageUrl+'fullimages/snaq-full-03.png',
      ],
      desc: 'Take the guesswork out of mealtime decisions. Free your mind from remembering nutritional values and their effect on your glucose levels. Use SNAQ for counting the carb, fat and protein content of meals. SNAQ also takes care of tracking glucose, insulin and activity data alongside your past meals.\n\n',
    },
    {
      name: 'social_diabetes',
      displayName: 'SocialDiabetes',
      url: 'https://www.socialdiabetes.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/app/apple-store/id727118400',
        google: 'https://play.google.com/store/apps/details?id=com.socialdiabetes.android&referrer=utm_source%3Dweb%26utm_campaign%3Dinstall_from_web',
      },
      icon: this.galleryImageUrl+'screenshots/social_diabetes-02.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/social_diabetes-screenshot-01.png',
        this.galleryImageUrl+'screenshots/social_diabetes-screenshot-02.png',
        this.galleryImageUrl+'screenshots/social_diabetes-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/social_diabetes-full-01.png',
        this.galleryImageUrl+'fullimages/social_diabetes-full-02.png',
        this.galleryImageUrl+'fullimages/social_diabetes-full-03.png',
      ],
      desc: 'SocialDiabetes is an award-winning digital management solution that helps people with diabetes improve their daily life and clinical outcomes by providing data driven personalized recommendations aiming to help keep your blood glucose levels stable.\n\nThe universal device integration allow to gather, register and track glucose levels, insulin dosage, carb intake, medications and physical activity...All this data are available for HCP real time monitoring, to create the best treatment for each patient\n\n',
    },
    {
      name: 'starfield',
      displayName: 'Starfield',
      url: 'https://starfield.health/',
      appStoreLink: {
      },
      icon: this.galleryImageUrl+'screenshots/starfield_logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/starfield_1.png',
        this.galleryImageUrl+'screenshots/starfield_2.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/starfield_1.png',
        this.galleryImageUrl+'fullimages/starfield_2.png',
      ],
      desc: 'Starfield connects people with their data, their care teams, and their care plans in one place.\n\n',
    },
    {
      name: 'steady_health',
      displayName: 'Steady Health',
      url: 'https://steady.health/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/app/id1458929653',
        google: 'https://play.google.com/store/apps/details?id=com.steadyhealth',
      },
      icon: this.galleryImageUrl+'screenshots/rectangular_single_letter.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/screen_2.png',
        this.galleryImageUrl+'screenshots/screen_3.png',
        this.galleryImageUrl+'screenshots/screen_1.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/screen_2.png',
        this.galleryImageUrl+'fullimages/screen_3.png',
        this.galleryImageUrl+'fullimages/screen_1.png',
      ],
      desc:
        'Steady Health provides technology-enabled modern care for diabetes. We specialize in CGM, use data and technology to understand our members, help them set goals, and coach them to get there. Book appointments, refill prescriptions and get coaching from our team of doctors and nurses through our simple app.\n' +
        '\n' +
        'We integrate with Dexcom CGM data and also enable our users to share insulin, exercise and much more.\n' +
        '\n' +
        'Get modern care and better health with our membership:\n' +
        '\n' +
        'Individualized treatment plans based on your data\n' +
        'Direct access to your doctor\n' +
        'Help with nutrition, exercise & technology\n' +
        'Improve your Time in Range, A1C, energy & mood\n' +
        'Now accepting members in our first San Francisco location\n\n',
    },
    {
      name: 'stenopool',
      displayName: 'Stenopool',
      url: 'https://linesystems.io/',
      appStoreLink: {
      },
      icon: this.galleryImageUrl+'screenshots/stenopool_logo.svg',
      screenShot: [
        this.galleryImageUrl+'screenshots/stenopool_1.png',
        this.galleryImageUrl+'screenshots/stenopool_2.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/stenopool_1.png',
        this.galleryImageUrl+'fullimages/stenopool_2.png',
      ],
      desc: 'Line Systems’ Stenopool aggregates and visualises diabetes data, streamlines hospital workflows, and enhances patient consultations\n\n',
    },
    {
      name: 'sugarmate',
      displayName: 'Sugarmate',
      url: 'https://www.sugarmate.io/ ',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/sugarmate/id1111093108?mt=8',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/sugarmate-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/sugarmate-screenshot-01.png',
        this.galleryImageUrl+'screenshots/sugarmate-screenshot-02.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/sugarmate-full-01.png',
        this.galleryImageUrl+'fullimages/sugarmate-full-02.png',
      ],
      desc: 'People living with type 1 diabetes, or their caregivers, have to deal with a steady stream of information that can often feel overwhelming. The Sugarmate™ app helps take away the guesswork by making the most of your continuous glucose monitoring (CGM) data. Built for smart devices across multiple platforms, the app uses CGM data to create a wide range of visualization tools that can streamline your diabetes management.\n\n',
    },
    {
      name: 'thorough_care',
      displayName: 'ThoroughCare',
      url: 'https://www.thoroughcare.net/ ',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/mycaretc/id1538803652',
        google: 'https://play.google.com/store/apps/details?id=com.thoroughcare.portal&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/thorough_care-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/thorough_care-screenshot-01.png',
        this.galleryImageUrl+'screenshots/thorough_care-screenshot-02.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/thorough_care-full-01.png',
        this.galleryImageUrl+'fullimages/thorough_care-full-02.png',
      ],
      desc: 'ThoroughCare’s Care Coordination software helps clinicians enhance patient engagement and achieve their value-based care goals. Integrating with Dexcom enables providers to participate in Remote Patient Monitoring and seamlessly monitor patients’ vitals via a user-friendly interface. ThoroughCare also provides notifications for patients along with their care managers for out-of-range readings, reading reminders, and more.\n\n',
    },
    {
      name: 'tidepool',
      displayName: 'Tidepool',
      url: 'https://tidepool.org/',
      appStoreLink: {
        apple: 'https://itunes.apple.com/us/app/tidepool-mobile/id1026395200',
        google: 'https://play.google.com/store/apps/details?id=io.tidepool.urchin',
      },
      icon: this.galleryImageUrl+'screenshots/tidepool-logo-120.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/iphone.png',
        this.galleryImageUrl+'screenshots/android.png',
        this.galleryImageUrl+'screenshots/web.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/iphone.png',
        this.galleryImageUrl+'fullimages/android.png',
        this.galleryImageUrl+'fullimages/web.png',
      ],
      desc: 'Tidepool is free, nonprofit software that liberates data from pumps, BGMs and CGMs and lets you see it all together in one place on the web or your phone.\n\n',
    },
    {
      name: 'twin_health',
      displayName: 'Twin Health',
      url: 'https://usa.twinhealth.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/twin-health/id1456423142',
        google: 'https://play.google.com/store/apps/details?id=com.twinsdigital.v2&hl=en_US',
      },
      icon: this.galleryImageUrl+'screenshots/twinhealth_logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/twinhealth_1.png',
        this.galleryImageUrl+'screenshots/twinhealth_2.png',
        this.galleryImageUrl+'screenshots/twinhealth_3.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/twinhealth_1.png',
        this.galleryImageUrl+'fullimages/twinhealth_2.png',
        this.galleryImageUrl+'fullimages/twinhealth_3.png',
      ],
      desc: 'Twin Health helps to prevent and improve chronic metabolic conditions with its breakthrough Whole Body Digital Twin technology, which delivers personalized, precision guidance across nutrition, activity, sleep, stress, and more.\n\n',
    },
    {
      name: 'una_health',
      displayName: 'Una Health',
      url: 'https://unahealth.de/en/intro',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/una-for-diabetes/id1634575950 ',
        google: 'https://play.google.com/store/apps/datasafety?id=io.unahealth.uh_app',
      },
      icon: this.galleryImageUrl+'screenshots/una_health_logo.svg',
      screenShot: [
        this.galleryImageUrl+'screenshots/una_health_1.png',
        this.galleryImageUrl+'screenshots/una_health_2.png',
        this.galleryImageUrl+'screenshots/una_health_3.png',
        this.galleryImageUrl+'screenshots/una_health_4.png',
        this.galleryImageUrl+'screenshots/una_health_5.png',
        this.galleryImageUrl+'screenshots/una_health_6.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/una_health_1.png',
        this.galleryImageUrl+'fullimages/una_health_2.png',
        this.galleryImageUrl+'fullimages/una_health_3.png',
        this.galleryImageUrl+'fullimages/una_health_4.png',
        this.galleryImageUrl+'fullimages/una_health_5.png',
        this.galleryImageUrl+'fullimages/una_health_6.png',
      ],
      desc: 'The certified Type 2 Diabetes program to understand your metabolism, identify your optimal lifestyle and build positive health behaviors.\n\n',
    },
    {
      name: 'under_my_fork',
      displayName: 'Undermyfork',
      url: 'https://www.undermyfork.com/',
      appStoreLink: {
        apple: 'https://apps.apple.com/us/app/undermyfork-diabetes-app/id1440135674',
        google: 'https://play.google.com/store/apps/details?id=com.undermyfork.diabetes&hl=en_US&gl=US',
      },
      icon: this.galleryImageUrl+'screenshots/umf_logo_512_0.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/under_my_fork-screenshot-01.png',
        this.galleryImageUrl+'screenshots/under_my_fork-screenshot-02.png',
        this.galleryImageUrl+'screenshots/under_my_fork-screenshot-03.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/under_my_fork-full-01.png',
        this.galleryImageUrl+'fullimages/under_my_fork-full-02.png',
        this.galleryImageUrl+'fullimages/under_my_fork-full-03.png',
      ],
      desc: 'Undermyfork lets you track what you eat in the simplest way possible: by overlaying your Dexcom CGM data over photos of your meals. Tag your meal photos with keywords to compare your glycemic response to the same meals and situations. The app then classifies meals by postprandial time-in-range and lets you analyze which meal choices may be best for you. You can filter the insights by tags you have added on meal photos.\n\n',
    },
    {
      name: 'validic',
      displayName: 'Validic',
      url: 'https://validic.com/',
      appStoreLink: {
        apple: '',
        google: '',
      },
      icon: this.galleryImageUrl+'screenshots/validic_circular-01.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/hero_graphic_2018.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/hero_graphic_2018.png',
      ],
      desc: "Validic guides healthcare organizations through the technical complexities associated with accessing and operationalizing patient-generated health data. Validic's scalable, secure solutions help you improve operational efficiency and patient outcomes by delivering personal health data from hundreds of home health devices seamlessly into your existing clinical workflows.\n\n",
    },
    {
      name: 'valuecare_suite',
      displayName: 'ValueCare Suite',
      url: 'https://valuecaresuite.com/',
      appStoreLink: {
      },
      icon: this.galleryImageUrl+'screenshots/valuecare_logo.png',
      screenShot: [
        this.galleryImageUrl+'screenshots/valuecare_1.png',
        this.galleryImageUrl+'screenshots/valuecare_2.png',
        this.galleryImageUrl+'screenshots/valuecare_3.png',
      ],
      fullImages: [
        this.galleryImageUrl+'fullimages/valuecare_1.png',
        this.galleryImageUrl+'fullimages/valuecare_2.png',
        this.galleryImageUrl+'fullimages/valuecare_3.png',
      ],
      desc: 'ValueCare Suite\'s automated workflows, alerts, and tasks empower clinical teams to prioritize patient care, not task management.\n\n',
    },
  ];

  currentPartner = this.galleryData[0];

  constructor() {}

  ngOnInit(): void {
  }

  createSupScript(text: string): HTMLElement {
    const element = document.createElement("sup");
    const verbiage = document.createTextNode(text);
    element.appendChild(verbiage);

    return element
  }

  openFullImageModal(imageURL: string) {
    this.fullImageUrl = imageURL;
    this.showBackdrop = true;
  }

  closeFullImageModal() {
    this.fullImageUrl = '';
    this.toggleAppModal();
  }

  toggleAppModal(event?: MouseEvent) {
    const dialog = document.getElementById('jquery_ajax_load') as HTMLInputElement;
    const anchorElement = event?.target as HTMLAnchorElement;
    if (anchorElement && !this.showBackdrop) {
      this.currentPartner = this.galleryData.filter((p) => {
        if (anchorElement) return anchorElement.id.indexOf(p.name) !== -1;
      })[0];
      this.currentPartner = this.currentPartner || this.galleryData[0];
    }
    if (dialog.style.display === 'block') {
      if (
        !$(anchorElement).closest('#jquery_ajax_load .modal-content').length ||
        anchorElement?.id === 'close_modal_button'
      ) {
        dialog.style.display = '';
        dialog.classList.remove('in');
        this.showBackdrop = false;
      }
      if (this.fullImageUrl) {
        dialog.style.display = '';
        dialog.classList.remove('in');
        this.showBackdrop = true;
      }
    } else {
      dialog.style.display = 'block';
      dialog.classList.add('in');
      this.showBackdrop = true;
    }
  }
}
