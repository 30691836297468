export class ConsentParams {
  private scope: string[] = [];

  constructor(
    private userId = '',
    private clientId = '',
    private redirectURI = '',
    private state = ''
  ) {}

  setScope(scope: string[]) {
    this.scope = scope;
  }

  getRedirectUrl(): string {
    return this.redirectURI;
  }

  getState(): string {
    return this.state;
  }

  getClientId(): string {
    return this.clientId;
  }
}
