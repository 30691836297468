import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConsentParams } from '../sandbox/consentParams';
import { Request } from './request';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private get apiURL(): string {
    // @ts-ignore
    return window['env'] ? window['env'].DEVELOPER_API_URL : 'www.apiurl.com';
  }
  constructor(private httpClient: HttpClient) {}

  public createCustomer(request: Request) {
    return this.httpClient.post<Request>(this.apiURL, request);
  }

  public setConsents(consentBody: ConsentParams) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.httpClient.post<{ authCode: string }>(
      `${this.apiURL}/grantConsent`,
      consentBody,
      options
    );
  }

  public getAppName(clientId: string) {
    return this.httpClient.get<{ appName: string }>(`${this.apiURL}/client`, {
      params: new HttpParams().set('clientId', clientId.toString()),
    });
  }
}
